<template>
    <div class="company-contain">
        <div class="form-contain" v-if="activeName === 'form'">
            <div class="head-box">
                <div class="title">企业介绍</div>
            </div>
            <div class="table-box">
                <el-form :model="introForm" :rules="rules" ref="introForm" label-width="200px" class="introForm">
                    <el-form-item label="企业名称：" prop="name">
                        <el-input placeholder="请输入企业名称" v-model="introForm.name" maxlength="30"
                                  show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="企业logo：" prop="logo">
                        <div class="upload-box">
                            <div class="upload-main" onclick="UploadInput.click()">
                                <i class="el-icon-circle-plus"></i>
                                <span>点击上传</span>
                            </div>
                            <input
                                    ref="fileInput"
                                    type="file"
                                    id="UploadInput"
                                    @change="uploadFile($event)"
                                    style="display:none"
                            />
                            <div class="img-box" v-if="introForm.logo !==''">
                                <img class="logo-box" :src="introForm.logo" alt="">
                                <i class="el-icon-error" @click="delLogo"></i>
                            </div>
                        </div>
                        <span style="color: #666;font-size: 12px">图片尺寸要求为300*300，仅支持jpg、png和gif</span>
                    </el-form-item>
                    <el-form-item label="企业简介：" prop="intro">
                        <el-input
                                v-model="introForm.intro"
                                type="textarea"
                                placeholder="请输入企业简介"
                                maxlength="150"
                                style="width: 410px;"
                                :autosize="{ minRows: 4, maxRows: 4}"
                                show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照注册号：" prop="registration_number">
                        <el-input placeholder="请输入营业执照注册号" v-model="introForm.registration_number"
                                  onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                    </el-form-item>
                    <el-form-item label="法定代表人姓名：" prop="person_name">
                        <el-input placeholder="请输入法定代表人姓名" v-model="introForm.person_name"></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照所在地：" prop="license_address">
                        <el-input placeholder="请输入营业执照所在地" v-model="introForm.license_address"></el-input>
                    </el-form-item>
                    <el-form-item label="企业注册资金：" prop="registered_capital">
                        <el-input placeholder="请输入企业注册资金" v-model="introForm.registered_capital"></el-input>
                    </el-form-item>
                    <el-form-item label="营业执照经营范围：" prop="business_scope">
                        <el-input placeholder="请输入营业执照经营范围" v-model="introForm.business_scope"></el-input>
                    </el-form-item>
                    <el-form-item label="公司地址：" prop="company_address">
                        <el-input placeholder="请输入公司地址" v-model="introForm.company_address"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话：" prop="phone">
                        <el-input placeholder="请输入联系电话" v-model="introForm.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱地址：" prop="email">
                        <el-input placeholder="请输入邮箱地址" v-model="introForm.email"></el-input>
                    </el-form-item>
                    <el-form-item label="注册时间：">
                        <el-date-picker type="date" placeholder="请选择时间" v-model="introForm.registration_date"
                                        value-format="yyyy-MM-dd"
                                        style="width: 410px;"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="店铺名称：" prop="shop_name">
                        <el-input placeholder="请输入店铺名称" v-model="introForm.shop_name"></el-input>
                    </el-form-item>
                    <el-form-item label="店铺网址：" prop="shop_url">
                        <el-input placeholder="请输入店铺网址" v-model="introForm.shop_url"></el-input>
                    </el-form-item>
                    <el-form-item label="企业资质：" prop="enterprise_qualification">
                        <div class="upload-box">
                            <div class="upload-main" onclick="UploadInput1.click()">
                                <i class="el-icon-circle-plus"></i>
                                <span>点击上传</span>
                            </div>
                            <input
                                    ref="fileInput"
                                    type="file"
                                    multiple
                                    id="UploadInput1"
                                    @change="uploadQa($event)"
                                    style="display:none"
                            />
                            <div class="img-box" v-for="(item,index) in qualification">
                                <img class="qa-box" :src="item.url" alt="">
                                <i class="el-icon-error" @click="delQa(index)"></i>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item class="footer-box">
                        <el-button type="primary" @click="submitForm('introForm')" class="left-btn">保存</el-button>
                        <el-button @click="resetForm('introForm')">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="show-contain" v-else>
            <div class="head-box">
                <div class="title">企业介绍</div>
            </div>
            <div class="table-box">
                <el-form :model="listData" ref="listForm" label-width="200px" class="introForm">
                    <el-form-item label="企业名称：">
                        <template>
                            <span>{{listData.name}}</span>
                        </template>
                    </el-form-item>
                    <el-form-item label="企业logo：">
                        <div class="upload-box">
                            <div class="showImg-box">
                                <img class="logo-box" :src="listData.logo.url" alt="">
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="企业简介：">
                        <template>
                            <div class="intro-box">{{listData.intro}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="营业执照注册号：">
                        <template>
                            <div class="intro-box">{{listData.registration_number?listData.registration_number:'暂无'}}
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="法定代表人姓名：">
                        <template>
                            <div class="intro-box">{{listData.person_name?listData.person_name:'暂无'}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="营业执照所在地：">
                        <template>
                            <div class="intro-box">{{listData.license_address?listData.license_address:'暂无'}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="企业注册资金：">
                        <template>
                            <div class="intro-box">{{listData.registered_capital?listData.registered_capital:'暂无'}}
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="营业执照经营范围：">
                        <template>
                            <div class="intro-box">{{listData.business_scope?listData.business_scope:'暂无'}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="公司地址：">
                        <template>
                            <div class="intro-box">{{listData.company_address}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="联系电话：">
                        <template>
                            <div class="intro-box">{{listData.phone}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="邮箱地址：">
                        <template>
                            <div class="intro-box">{{listData.email}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="注册时间：">
                        <template>
                            <div class="intro-box">{{listData.registration_date?listData.registration_date:'暂无'}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="店铺名称：">
                        <template>
                            <div class="intro-box">{{listData.shop_name?listData.shop_name:'暂无'}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="店铺网址：">
                        <template>
                            <div class="intro-box">{{listData.shop_url?listData.shop_url:'暂无'}}</div>
                        </template>
                    </el-form-item>
                    <el-form-item label="企业资质：">
                        <div class="upload-box" v-if="listData.enterprise_qualification.length > 0">
                            <div class="showImg-box" v-for="item in listData.enterprise_qualification">
                                <img class="qa-box" :src="item.url" alt="">
                            </div>
                        </div>
                        <div class="no-data" v-else>暂无</div>
                    </el-form-item>
                    <el-form-item class="footer-box">
                        <el-button type="primary" @click="editForm" class="left-btn">编辑</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "companyIntro",
        data() {
            // 验证手机
            let checkPhone = (rule, value, callback) => {
                let reg = /^1[345789]\d{9}$/
                if (!reg.test(value)) {
                    callback(new Error('请输入11位手机号'))
                } else {
                    callback()
                }
            }
            return {
                activeName: 'form',
                introForm: {
                    name: '',
                    logo: '',
                    intro: '',
                    registration_number: '',
                    person_name: '',
                    license_address: '',
                    registered_capital: '',
                    business_scope: '',
                    company_address: '',
                    phone: '',
                    email: '',
                    registration_date: '',
                    shop_name: '',
                    shop_url: '',
                    enterprise_qualification: [],
                },
                listData: {},
                rules: {
                    name: [
                        {required: true, message: '请输入企业名称', trigger: 'blur'},
                        {min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur'}
                    ],
                    intro: [
                        {required: true, message: '请输入企业简介', trigger: 'blur'},
                    ],
                    logo: [
                        {required: true, message: '请上传企业logo', trigger: 'blur'},
                    ],
                    company_address: [
                        {required: true, message: '请输入公司地址', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                        {validator: checkPhone, message: '请输入正确的手机号', trigger: 'blur'}
                    ],
                    email: [
                        {required: true, message: '请输入邮箱', trigger: 'blur'},
                        {type: 'email', message: '请输入正确的邮箱', trigger: 'blur'}
                    ],

                },
                logo: "",
                logoName: "",
                qualification: [],
            };
        },
        mounted() {
            this.getCompanyIntro();
        },
        methods: {
            getCompanyIntro() {
                this.$httpcompany.axiosGet(this.$api.enterprise_getEnterpriseDetail, res => {
                    if (res.code == 200) {
                        this.listData = res.data.company_profile;
                        if (this.listData !== null && JSON.stringify(this.listData) !== '{}' && JSON.stringify(this.listData) !== '[]') {
                            this.activeName = 'list'
                        }
                    }
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.introForm.shop_url !== '') {
                            let strRegex = '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
                            let re = new RegExp(strRegex);
                            if (!re.test(this.introForm.shop_url)) {
                                this.$message.warning("请输入正确的店铺网址");
                                return false;
                            }
                        }
                        this.introForm.enterprise_qualification = JSON.stringify(this.introForm.enterprise_qualification)
                        this.$httpcompany.axiosPost(this.$api.enterprise_saveEnterprise, this.introForm, res => {
                            if (res.code == 200) {
                                this.$message.success(res.message);
                                this.getCompanyIntro();
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.qualification = [];
                this.$refs[formName].resetFields();
                this.getCompanyIntro();
            },
            editForm() {
                this.activeName = 'form';
                this.formatIntro();
            },
            formatIntro() {
                let arr = JSON.parse(JSON.stringify(this.listData));
                if(typeof arr.enterprise_qualification == "string") {
                    this.qualification = JSON.parse(arr.enterprise_qualification);
                } else {
                    this.qualification = arr.enterprise_qualification;
                }
                arr.logo = this.listData.logo.url;
                arr.enterprise_qualification = [];
                delete arr.id;
                delete arr.user_id;
                delete arr.create_time;
                this.introForm = arr;
                this.formatQa();
            },
            uploadFile(event) {
                let fileName = event.target.files[0].name;
                let limiteFileType = ["jpg", "png", "gif"];
                if (
                    !limiteFileType.includes(
                        fileName.split(".")[fileName.split(".").length - 1]
                    )
                ) {
                    this.$message.warning("文件格式错误！");
                    return;
                }
                this.logoName = fileName;
                this.logo = event.target.files[0];
                let formData = new FormData();
                formData.append('file', this.logo);
                this.$httpcompany.axiosPost(this.$api.enterprise_uploadEnterprise, formData, res => {
                    if (res.code == 200) {
                        this.introForm.logo = res.data.url;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            uploadQa(event) {
                let qa = event.target.files;
                let formData = new FormData();
                let len = qa.length;
                for (let i = 0; i < len; i++) {
                    let fileName = event.target.files[i].name;
                    let limiteFileType = ["jpg", "png", "gif"];
                    if (
                        !limiteFileType.includes(
                            fileName.split(".")[fileName.split(".").length - 1]
                        )
                    ) {
                        this.$message.warning("文件格式错误！");
                        return;
                    }
                    formData.append('images[]', qa[i]);
                }
                this.$httpcompany.axiosPost(this.$api.enterprise_uploadImages, formData, res => {
                    if (res.code == 200) {
                        if (this.qualification.length > 0) {
                            for (let i = 0; i < res.data.length; i++) {
                                this.qualification.push(res.data[i])
                            }
                        } else {
                            this.qualification = res.data;
                        }
                        this.formatQa();
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            delLogo() {
                this.introForm.logo = '';
            },
            delQa(index) {
                this.qualification.splice(index, 1)
            },
            formatQa() {
                this.introForm.enterprise_qualification = [];
                this.qualification.forEach(item => {
                    this.introForm.enterprise_qualification.push(item.url)
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    .company-contain {
        height: 100%;
        background-color: #ffffff;

        .head-box {
            display: flex;
            height: 40px;
            padding: 10px 30px 0 20px;
            position: relative;

            .title {
                margin-top: 10px;
            }
        }

        .table-box {
            padding-top: 20px;
            padding-bottom: 30px;

            .introForm {
                .el-input {
                    width: 410px;
                }

                .upload-box {
                    display: flex;
                    flex-wrap: wrap;

                    .upload-main {
                        width: 100px;
                        height: 100px;
                        cursor: pointer;
                        background: #EFF0FF;
                        border-radius: 2px;
                        color: #4D5AFF;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 16px;
                        }
                    }

                    .showImg-box {
                        margin-left: 20px;
                        margin-bottom: 20px;

                        &:nth-child(8n) {
                            margin-left: 0px;
                        }

                        &:nth-child(1) {
                            margin-left: 0px;
                        }

                        .logo-box {
                            width: 100px;
                            height: 100px;
                            border: 1px solid #EEEEEE;
                            border-radius: 2px;
                        }

                        .qa-box {
                            max-width: 154px;
                            max-height: 212px;
                        }
                    }

                    .img-box {
                        margin-left: 40px;
                        position: relative;

                        &:nth-child(3) {
                            margin-left: 20px;
                        }

                        .logo-box {
                            width: 100px;
                            height: 100px;
                            border: 1px solid #EEEEEE;
                            border-radius: 2px;
                        }

                        .qa-box {
                            max-width: 154px;
                            max-height: 212px;
                        }

                        .el-icon-error {
                            width: 14px;
                            height: 14px;
                            color: #4D5AFF;
                            cursor: pointer;
                            position: absolute;
                            right: -20px;
                            top: 0;
                        }
                    }
                }

                .intro-box {
                    width: 400px;
                    white-space: normal;
                    word-break: break-all;
                    word-wrap: break-word;
                }
            }

            .footer-box {
                justify-content: center;
                display: flex;

                button {
                    width: 80px;
                    height: 32px;
                    line-height: 4px;
                    border-radius: 2px;
                }

                .left-btn {
                    background: #4D5AFF;
                }
            }
        }

        .form-contain {
            .head-box {
                &:after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background: #E5E5E5;
                    bottom: -5px;
                    left: 10px;
                    right: 10px;
                }
            }
        }
    }
</style>